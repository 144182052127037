import Button from "@mui/material/Button";
import { useRouter } from 'next/router'

// Auth
import { useAuthenticator } from "@aws-amplify/ui-react";

const SignInMenu = (props) => {
  const router = useRouter();

  const handleSignIn = () => {
    props.handleSetShowLogin();
  };

  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const handleSignOut = async () => {
    props.handleSetShowLogin();
    await signOut();
  };

  if (!user) {
    return (
      <>
        <Button onClick={handleSignIn} key={"signIn"} sx={{ my: 2, color: "unset", display: "block" }}>
          Login
        </Button>
      </>
    );
  } else {
    return (
      <Button onClick={handleSignOut} key={"LogIn"} sx={{ my: 2, color: "unset", display: "block" }}>
        Sign Out
      </Button>
    );
  }
};

export default SignInMenu;